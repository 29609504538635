import React, { useState, useEffect } from 'react';
import './experience.css';
import { useNavigate } from 'react-router-dom';

function Experience() {  
  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(0); // State to control menu visibility
  const [topbarShow, setTopbarShow] = useState(0); // State to control menu visibility
  const navigate = useNavigate();

  useEffect(() => {
    let timers = []; // Array to store timers

    if (menuOpen) {
      setTopbarShow(1)
      setTimeout(() => {
      // Schedule timers for incrementally showing menu items
      for (let i = 1; i <= 5; i++) {
        const timer = setTimeout(() => {
          setShowMenu(i);
        }, i * 100); // Delay each item by 500ms
        timers.push(timer);
      }
    }, 100); 
    } else {
      setShowMenu(0);
      setTimeout(() => {
        setTopbarShow(0);
      }, 200);
    }

    // Cleanup all timers on component unmount or when menuOpen changes
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [menuOpen]);

  const handleMenuClick = (componentName) => {
    navigate('/' + componentName);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const items_one = [
    "Role and Responsibilities:  My work focused on accurately reporting trading PnL and assessing the health of active positions relative to trading limits across gas, power, emissions, shipping and products teams.",
    "Technical Skills: Proficient in Python (pandas, plotly), PowerBI, Excel, and VBA (which I self-taught for automating workflow and report production), Endur and Symphony.",
    "Contributions: Automation of the company's VAR calculations, collaborated across the entire business to connect teams, and leveraged my shipping knowledge to develop the Shipping Business Unit monitoring and reporting."
  ];

  const items_two = [
    "Entrepreneurship and Leadership: Solo founder that built a team to collaborate on the development of an augmented reality mobile application. I led a team of four developers and several contractors using agile methods to manage operations, product management, and to successfully build an alpha product.",
    "Technical and Design Skills: Skilled in front-end development with React, TypeScript, Dart, and Flutter, and possess expertise in UX/UI design to create enhanced user experiences.",
    "Business Acumen and Networking: Successfully secured research and entrepreneurship grants through fundraising, pitching and marketing. Managed the finances and accounting of the business and used my strong networking abilities to garner investor relationships."
  ];

  const items_three = [
    "Internship Outcomes: Worked with investment teams to design a new CRM system architecture, utilizing Dynamics, Excel, Word, PowerPoint, PowerBI, Zendesk, and Mailchimp to optimize data processing and client communication.",
    "Project Management Skills: Employed GANTT charts and RACI matrices to organize the CRM rollout.",
    "Investment Experience: Participation in investment meetings, facilitated calculations of LTV and CAC, and leveraged my experience from Mirage to provide insights into the AR/VR/Crypto/Web3 sector for SEIS and EIS fund decisions."
  ];

  const items_four = [
    "Financial Modeling: Used Python (pandas, numpy, scipy, plotly, matplotlib) and Microsoft Excel, conducting multivariate regression data analysis for a sustainable financial products team.",
    "Carbon Markets Focus: Primarily focussed analysis on carbon and commodities markets to aid in structuring eco-financial deals with various shipping businesses.",
    "Business Development: Led client pitches to sell shipping clients OTC EUAs for speculation and hedging against EU ETS regulations."
  ];

  const items_five = [
  ];

  const items_six = [
    "Advanced Mathematics: Covered differential and integral calculus (including partial, total, and N-dimensional differentiation), vector calculus, Dirac delta function, Fourier analysis, complex analysis, and linear algebra, focusing on ODEs, complex functions, Cauchy-Riemann equations, contour integrals, Cauchy's residue theorem, and Green functions for ODEs and PDEs.",
    "Computing and Data Science: Utilised Python (NumPy, SciPy, Pandas, Plotly, and Matplotlib) for data cleaning and visualization, applied correlation and multiple linear regression to complex datasets, performed principal component analysis for dimensionality reduction, and developed algorithms to solve physical problems, ensuring datasets from diverse sources were consistently structured and scientifically presented.",
    "Advanced Physics Topics Studied:  Special Relativity (length contraction, time dilation, Lorentz transformations), Electromagnetism (Maxwell's equations, magnetic fields, optical phenomena), Quantum Physics (Schrödinger equation, quantum models, wavefunctions), Statistical Mechanics (micro- and macro-states, entropy, Boltzmann distribution), Classical Mechanics, Properties of Matter and Thermodynamics, Oscillations and Waves (harmonic oscillators, wave motion), and solid-state physics including crystal structures, electron transport, and semiconductor physics."
  ];

  return (
    <div className="app-container">
      <div className="top-bar">
        <button className={`arrow-left ${topbarShow ? (menuOpen >= 1 ? 'hide' : 'show') : ''}`} onClick={() => handleMenuClick('')}>
          Home
        </button>
        {showMenu > 0 && (
          <button className="topbar-menu-title" onClick={toggleMenu}>
            Back
          </button>
        )}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {showMenu > 0 && (
          <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
            <div className='line'></div>
            <div className="column">
              <div className="center-column">
                <button
                  className={`topbar-menu-buttons ${showMenu >= 2 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('')}
                >
                  Home
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 3 ? 'open' : ''}`}
                  onClick={toggleMenu}
                >
                  Experience
                </button>
                <button
                  className={`topbar-menu-buttons ${showMenu >= 4 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('projects')}
                >
                  Projects
                </button>
                <button
                  className={`topbar-menu-buttons-last ${showMenu >= 5 ? 'open' : ''}`}
                  onClick={() => handleMenuClick('contact')}
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="column-container">
      <div className="fixed-content">
            <p className="fixed-content-title">Connect</p>
            <p className="fixed-content-text">If you are driven by creating value, are passionate about technology, and have insatiable curiosity, feel free to connect with me on LinkedIn!</p>
            <div className="icon-container">
              <a href="mailto:anthonyduncalf_AT_gmail.com">
                <img src="/Assets/email.png" alt="Email Icon" className="icon"/>
              </a>
              <a href="https://github.com/SovietMeteor10" target="_blank" rel="noopener noreferrer">
                <img src="/Assets/github.png" alt="GitHub Icon" className="icon"/>
              </a>
              <a href="https://www.linkedin.com/in/aduncalf/" target="_blank" rel="noopener noreferrer">
                <img src="/Assets/linkedin.png" alt="LinkedIn Icon" className="icon"/>
              </a>
            </div>
        </div>    
        <div className={`experience-column ${menuOpen ? 'open' : ''}`}>
          <div className="section-title">
            Experience
          </div> 
          <div className='sections-column'>
            <div>
              <div className="experience-title-line">
                <div className="experience-title">
                  Petroineos - Risk Analyst
                </div>
                <div className="experience-dates">
                  Apr'23 - Oct'24
                </div>
              </div>
              <ul className="bullet-point-list"> {/*number 1*/}
                {items_one.map((item, index) => (
                  <li key={index} className="list-item">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="experience-title-line">
                <div className="experience-title">
                  Mirage Technology - Founder
                </div>
                <div className="experience-dates">
                  Apr'21 - Jul'23
                </div>
              </div>
              <ul className="bullet-point-list"> {/*number 2*/}
                {items_two.map((item, index) => (
                  <li key={index} className="list-item">
                    {item}
                  </li> 
                ))}
              </ul>
            </div>
            <div>
              <div className="experience-title-line">
                <div className="experience-title">
                  Guinness Ventures - Data Analyst
                </div>
                <div className="experience-dates">
                  Feb'22 - Dec'22
                </div>
              </div>
              <ul className="bullet-point-list"> {/*number 3*/}
                {items_three.map((item, index) => (
                  <li key={index} className="list-item">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div>
            <div className="experience-title-line">
              <div className="experience-title">
                Affinity Shipping - Data Scientist
              </div>
              <div className="experience-dates">
                Aug'21 - Feb'22
              </div>
            </div>
            <ul className="bullet-point-list"> {/*number 4*/}
              {items_four.map((item, index) => (
                <li key={index} className="list-item">
                  {item}
                </li>
              ))}
            </ul>
            <div className="section-title-education">
              Education
            </div>
            <div className="experience-title-line">
              <div className="experience-title">
                MSc, Physics - Imperial College London
              </div>
              <div className="experience-dates">
                Sep'24 - Sep'25
              </div>
            </div>
            <div className="experience-blurb">
              <p>Grade: TBC</p>
              <p>Modules: C++ Programming, Machine Learning, Algorithms, Databases, Software Engineering Design, AI Ventures.</p>
            </div>
            <ul className="bullet-point-list"> {/*number 4*/}
              {items_five.map((item, index) => (
                <li key={index} className="list-item">
                  {item}
                </li>
              ))}
            </ul>
            <div className="experience-title-line">
              <div className="experience-title">
                BSc, Physics - University of Bristol
              </div>
              <div className="experience-dates">
                Sep'18 - Jul'21
              </div>
            </div>
            <div className="experience-blurb">
              First Class Honours
            </div>
            <ul className="bullet-point-list-last"> {/*number 4*/}
              {items_six.map((item, index) => (
                <li key={index} className="list-item">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Experience;
